import moment from 'moment';
import {
  fetchAllInterlocutors,
  fetchCalendlyData,
  fetchMedicalFile,
  fetchMedicalFileFileTypes,
  fetchMedicalFilePayment,
  fetchMedicalFileTravelData,
} from '../../services/api';

export const SET_MEDICAL_FILE = 'SET_MEDICAL_FILE';
export const MEDICAL_FILE_IS_FETCHING = 'SET_PATIENT_IS_FETCHING';

const setMedicalFile = (medicalFile) => ({ type: SET_MEDICAL_FILE, medicalFile });
const isFetching = () => ({ type: MEDICAL_FILE_IS_FETCHING });

export const fetchMedicalFileData = (reference) => async (dispatch) => {
  dispatch(isFetching());
  const { data } = await fetchMedicalFile(reference);
  const interlocutors = await fetchAllInterlocutors(reference);
  const payment = await fetchMedicalFilePayment(reference).catch(() => null);
  const { data: fileTypes } = await fetchMedicalFileFileTypes(reference).catch(() => null);
  const traveldata =
    data.type >= 1 ? await fetchMedicalFileTravelData(reference).catch(() => null) : null;
  if (traveldata) {
    if (traveldata.calendar_link) {
      if (traveldata.calendar_link.startsWith('http')) {
        const inventee = await fetchCalendlyData(traveldata.calendar_link);
        traveldata.event = inventee ? await fetchCalendlyData(inventee.event) : null;
        traveldata.meetup = inventee;
      } else {
        const eventURL = `https://api.calendly.com/scheduled_events/${traveldata.calendar_link}`;
        traveldata.event = await fetchCalendlyData(eventURL);
      }
    }

    traveldata.exit = traveldata.exit ? moment(traveldata.exit) : null;
  }
  return dispatch(setMedicalFile({ ...data, interlocutors, payment, traveldata, fileTypes }));
};
